import React, { Component, useEffect } from 'react';
import Backdrop from './backdrop'
import Img from 'gatsby-image'


class Popup extends Component {
    constructor(props){
        super(props);
        this.state = {
          showPopup: false
        }
      }
      toggle() {
        this.setState({showPopup: !this.state.showPopup});
      }
    
    componentDidMount(){
        const timer = setTimeout(() => {
            this.setState({showPopup: true})
        }, 3000);
    }
    render(){
        return(
            
            <div>{
                this.state.showPopup ?
                <div className="zindextop animated fadeIn slow">
                    <div className="relative ">
                     <div className="backdrop" onClick={this.toggle.bind(this)}></div>
                    </div>
                <div className="popup">
                </div>
                <div className="tag">
                    <div className="ribbon ribbon-top-left"><span>PROMO</span></div>
                </div>
                <div className="popup-text">
                    <h1>Diágnostico Preliminar Gratis</h1>            
                    <ul className="tablet">
                    <p>Analizaremos tus necesidades y resolveremos todas tus dudas respecto a lo que tu empresa necesita</p>   
                    <p>Implementación de Normas ISO:</p>
                        <li><span className="orange">ISO 9001:2015 </span>- Gestión de Calidad</li>
                        <li><span className="orange">ISO 14001:2015</span> - Gestión Ambiental</li>
                        <li><span className="orange">ISO 45001:2018</span> - Gestión de Seguridad y Salud en el Trabajo</li>
                        <li><span className="orange">ISO 22301:2012</span> - Gestión de Continuidad de Negocio </li>
                        <li><span className="orange">ISO 31000:2018</span> - Gestión de Riesgos</li>
                        <li><span className="orange">ISO 27001:2013</span> - Gestión de la Seguridad de la Información</li>
                    </ul>
                    <form className="popup-form" name="popup-contact" method="POST" action="https://formspree.io/xgevwlao">
                        <input type="hidden" name="form-name" value="popup-contact" />
                        <input placeholder="Jhon Doe" name="nombre" type="text" required/>
                        <input placeholder="Doe Corp" name="empresa" type="text" required/>
                        <input placeholder="jhon.doe@doecorp.com" name="email" type="email" required/>
                        <input placeholder="R.U.C." className="tablet" name="ruc" type="number"/>
                        <input placeholder="Teléfono" name="telefono" type="phone" required/>
                        <input placeholder="Gerente General" className="tablet" name="cargo" type="text"/>
                        <br/>
                        <p style={{textAlign: "center"}}>
                            <input type="submit" value="Enviar" style={{backgroundColor: "#f58220"}}  />
                        </p>
                    </form>
                </div>
                </div>               
                :null
            }
                
            </div>
        )}
    }

export default Popup