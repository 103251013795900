import React from "react"

import Layout2 from "../components/layout2"
import SEO from "../components/seo"
import ServicesComponent from "../components/servicescomp"

import Carousel from "../components/carousel"
import BlogComponent from "../components/blogcomp"
import Img from "gatsby-image"
import Popup from "../components/popup"
import Clients from "../components/clients"

const IndexPage = ({ data }) => {
  return (
    <Layout2>
      <SEO
        title="Home"
        keywords={[
          `ppasesoriaintegral`,
          `implementacion-iso`,
          `asesoria integral`,
        ]}
      />
      <section className="jumbotron" id="jumbotron" name="jumbotron">
        <div className="container">
          <div className="picture">
            <Carousel />
          </div>
        </div>
      </section>
      <ServicesComponent />
      {/*       <Popup />
       */}{" "}
      <section name="clientes" className="clientes">
        <div className="heading">
          <h1>Clientes</h1>
          <h3>Ellos ya confían en nosotros</h3>
        </div>
        <div className="container">
          {/*  <a
              className="clientesimg"
              href="https://promedonperu.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="image">
                <Img
                  fluid={promedon.childImageSharp.fluid}
                  alt="Promedon Peru"
                />
              </div>
            </a>
            <a
              className="clientesimg"
              href="https://eiposgrado.edu.pe/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="image">
                <Img
                  fluid={eisposgrado.childImageSharp.fluid}
                  alt="EISPOSGRADO"
                />
              </div>
            </a>
           */}
          <Clients />
        </div>
      </section>
      <BlogComponent />
    </Layout2>
  )
}
export default IndexPage
